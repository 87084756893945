import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Scripts from '../components/layouts/Scripts';
import { Container, Row, Col } from 'react-bootstrap';
import JobStatusColumn from '../components/layouts/statusCard';
import ProfileStatusColumn from '../components/layouts/statusCardProfile';
import { getAllEmployerBoosted, getAllCrewBoosted 
      } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';

const CrewDiscoverScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/discover_page.css" />
    </>
  );
};

const CrewDiscoverScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CrewDiscover = () => {
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");
  const [activeTab, setActiveTab] = useState('Jobs');

  // get list for empoyer boosted Starts
  const [jobListEmployerBoosted , setJobListEmployerBoosted] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getAllEmployerBoosted(userToken);
        setJobListEmployerBoosted(response);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } 
    };

    fetchJobs();
  }, []);

  const userBoostList = [];
  const idSet = new Set();

  jobListEmployerBoosted.forEach(group => {
    Object.entries(group).forEach(([userId, jobs]) => {
        jobs.forEach(job => {
            if (!idSet.has(job.user_boost.id)) {
                userBoostList.push({
                    userData: job.user_boost,
                    statusData: group[userId]
                });
                idSet.add(job.user_boost.id);
            }
        });
    });
});

  // get list for empoyer boosted Ends

  // empoyer boosted Selected User Data Starts
  const [selectedUserStatusData, setSelectedUserStatusData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (allSatusData, index) => {
    setSelectedUserStatusData(allSatusData.statusData);
    setSelectedUser(allSatusData.userData);
    setActiveIndex(index);
  };
  // empoyer boosted Selected User Data Ends

  // crew boosted Data Starts
  const [jobListCrewBoosted , setJobListCrewBoosted] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getAllCrewBoosted(userToken);
        setJobListCrewBoosted(response);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } 
    };
    fetchJobs();
  }, []);

  const userCrewBoostList = [];

  if (jobListCrewBoosted) {
    jobListCrewBoosted.forEach( eachUser => {
        userCrewBoostList.push({
          userdata:eachUser.user_boost.user_id,
          userprofiledata:eachUser.user_boost
        }
      );
    })
  }

  const [selectedUserStatusDataCrew, setSelectedUserStatusDataCrew] = useState(null);
  const [selectedUserCrew, setSelectedUserCrew] = useState(null);
  const [activeIndexCrew, setActiveIndexCrew] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClickCrew = (allSatusData, index) => {
    setSelectedUserStatusDataCrew(allSatusData.userprofiledata);
    setSelectedUserCrew(allSatusData.userdata);
    setActiveIndexCrew(index);
  };

  const handleNext = () => {
      setCurrentIndex(prevIndex => Math.min(prevIndex + 1, userCrewBoostList.length - 1));
  };

  const handlePrevious = () => {
      setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  
  // crew boosted Data Ends

  return (
    <>
        <CrewDiscoverScriptCss />
        <NavbarCrew />

        <div>
          <div className="job-tabs-container">
            <div className="header-text-1">
              <div className="container">
                <h1 className="landing-header">Discover Premium Jobs &amp; Profiles</h1>
              </div>
              <div className="container job-tabs">
                <h1
                  className={`tab-header ${activeTab === 'Jobs' ? 'active' : ''}`}
                  onClick={() => setActiveTab('Jobs')}
                >
                  Jobs
                </h1>
                <h1
                  className={`tab-header ${activeTab === 'Profiles' ? 'active' : ''}`}
                  onClick={() => setActiveTab('Profiles')}
                >
                  Profiles
                </h1>
              </div>
            </div>
            
          </div>
          {activeTab === 'Jobs' && (
            <div className="job-status-tab">
              <Container>
                <Row>
                  <Col md={6} className="job-status-by-container">
                    <div className="status-posted-by-container">
                      {userBoostList && (
                        userBoostList.map((boostedJob, index) => (
                          boostedJob.userdata && (
                            <div key={`boostedJob_${index}`} onClick={() => handleClick(boostedJob, index)} 
                            className={`job-status-by-record-container ${index === activeIndex ? 'active' : ''}`}
                            >
                              <div className="job-status-by-record">
                                <LazyLoadImage className="job-status-by-record-image" src={boostedJob.userData.profilePic} alt="Profile" />
                                <div className="job-status-by-name-container">
                                  <p className="job-status-by-name">{boostedJob.userData.first_name} {boostedJob.userData.last_name}</p>
                                  <p className="job-status-by-company">{boostedJob.userData.company_name}</p>
                                </div>
                              </div>
                            </div>
                          )
                        ))
                      )}

                    </div>
                  </Col>

                  {!userBoostList && (
                    <Col md={6} className="job-status-container">

                    <div className="empty-data-container">
                        <LazyLoadImage className="empty-data-image" src="/static/crew/assets/img/images/empty_img.svg" alt="Empty" />
                        <h1 className="empty-tab-text">No Data Available!</h1>
                    </div>
                    </Col>
                  )}
                  <Col md={6} className="job-status-container">
                    {selectedUserStatusData && (
                      <JobStatusColumn userSelectedData={selectedUser} userStatusDataList={selectedUserStatusData} />
                    )}
                  
                    { !selectedUserStatusData && (
                      <div className="first-time-msg-container">
                          <LazyLoadImage className="first-time-tab-img" src="/static/crew/assets/img/images/discover_jobs.svg" alt="Discover Jobs" />
                          <div className="first-time-tab-text-container">
                              <h1 className="first-time-tab-text-header">Unlock Elite Opportunities!</h1>
                              <h1 className="first-time-tab-text">Discover Premium Jobs and Top Crew Profiles</h1>
                          </div>
                      </div>
                    )}
                  </Col>



                </Row>
              </Container>
            </div>
          )}

          {activeTab === 'Profiles' && (
            <div className="profiles-status-tab">
              <div className="container">
                  <div className="row">
                      <div className="col-md-6 job-status-by-container">
                          <div className="status-posted-by-container">
                            {userCrewBoostList && (
                                userCrewBoostList.map((crewUserList, index) => (
                                  crewUserList.userdata && (
                                    <div key={`boostedJob_${index}`} onClick={() => handleClickCrew(crewUserList, index)} 
                                      className={`job-status-by-record-container ${index === activeIndexCrew ? 'active' : ''}`}
                                    >
                                      <div className="job-status-by-record">
                                        <LazyLoadImage className="job-status-by-record-image" src={crewUserList.userdata.profilePic} alt="Avatar" />
                                        <div className="job-status-by-name-container">
                                          <p className="job-status-by-name">{crewUserList.userdata.first_name}</p>
                                          <p className="job-status-by-company">{crewUserList.userdata.rank_id.name}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ) 
                                ))
                              )}
                          </div>
                      </div>

                      <div className="col-md-6 job-status-container">
                        {!userCrewBoostList && (
                          <div className="empty-data-container">
                              <LazyLoadImage className="empty-data-image" src="/static/crew/assets/img/images/empty_img.svg" alt="Empty" />
                              <h1 className="empty-tab-text">No Data Available!</h1>
                          </div>
                        )}
                        {selectedUserStatusDataCrew && (
                          <>
                            <ProfileStatusColumn userSelectedData={selectedUserCrew} userStatusDataList={selectedUserStatusDataCrew} />
                            {/* <div className="status-control-button">
                                <button className="btn btn-primary status-control-button-prev" type="button" onClick={handlePrevious}>
                                    <LazyLoadImage src="/static/crew/assets/img/arrows/ArrowLeftorange.svg" alt="Previous" />&nbsp; Previous
                                </button>
                                <button className="btn btn-primary status-control-button-next" type="button" onClick={handleNext}>
                                    Go Next&nbsp;&nbsp;<LazyLoadImage src="/static/crew/assets/img/arrows/ArrowRightwhite.svg" alt="Next" />
                                </button>
                            </div> */}
                          </>
                        )}


                        { !selectedUserCrew && (
                          <div className="first-time-msg-container">
                              <LazyLoadImage className="first-time-tab-img" src="/static/crew/assets/img/images/discover_jobs.svg" alt="Discover Jobs" />
                              <div className="first-time-tab-text-container">
                                  <h1 className="first-time-tab-text-header">Unlock Elite Opportunities!</h1>
                                  <h1 className="first-time-tab-text">Discover Premium Jobs and Top Crew Profiles</h1>
                              </div>
                          </div>
                        )}

                    </div>

                  </div>
              </div>
            </div>
          )}
        </div>
        <Scripts />
        <CrewDiscoverScript />
    </>
  );
};

export default CrewDiscover