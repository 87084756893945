import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { apiRoutes } from './config/apiConfig';
import Layout from './components/layouts/Layout';
import LayoutNoToken from './components/layouts/Layout_without_token';
import Home from './pages/Home';
import HomeCrew from './pages/HomeCrew';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup_usertype';
import SignupCrew from './pages/Signup_crew';
import CrewConfirmEmail from './pages/Signup_crew_confirm';
import CrewCreateProfileStep1 from './pages/crew_create_profile1';
// import Page404 from './pages/Page404';
import CrewCreateProfileStep2 from './pages/crew_create_profile2';
import CrewCreateProfileStep3 from './pages/crew_create_profile3';
import SignupEmployee from './pages/Signup_emp_type';
import SignupEmployeeMobile from './pages/Signup_emp_mobile';
import SignupEmployeeMobileVerify from './pages/Signup_emp_mobile_verify'; 
import SignupEmployeeForm from './pages/Signup_emp_form';
import EmployerConfirmEmail from './pages/Signup_emp_confirm';
import EmployerCreateProfile from './pages/employer_create_profile';
import CrewFindJobs from './pages/CrewFindJobs';
import CrewDiscover from './pages/CrewDiscover';
import CrewJobRefer from './pages/CrewJobRefer';
import CrewMyJobs from './pages/CrewMyJobs';
import CrewJobReferEdit from './pages/CrewJobReferEdit';
import CompanyJobs from './pages/CompanyJobs';

import TestApp from './pages/testApi';
import FirebaseToken from './pages/firebaseToken';
import LoginTokenWithFirebaseToken from './pages/loginToken';
import DemoApi from './pages/demoAPiTest';
import ClearLocalStorage from './pages/clearLocalStorage';
import JobShow from './pages/JobShow';
import CrewSubscriptionPlan from './pages/CrewSubscriptionPlan';
import TermsAndConditions from './pages/termsAndConditions';
import PrivacyPolicies from './pages/privacyPolicies';
import CrewWallet from './pages/CrewWallet';
import ReferAndEarn from './pages/referAndEarn';
import CrewHelpSupport from './pages/CrewhelpAndSupport';
import CrewSettings from './pages/CrewSettings';
import LogInWithMobile from './pages/Login_mobile';
import HomeEmployer from './pages/HomeEmployer';
import EmployerFindJobs from './pages/EmployerFindJobs';
import PostJob from './pages/PostJob';
import PostJobEdit from './pages/PostJobEdit';
import CvSearch from './pages/CvSearch';
import EmployerViewApplicants from './pages/employerViewApplicants';
import EmployerMyJobs from './pages/EmployerMyJobs';
import EmployerViewProfile from './pages/employerViewProfile';
import EmployerViewApplicantProfile from './pages/employerViewApplicantProfile';
import EmployerSubscriptionPlan from './pages/EmployerSubscriptionPlan';
import ManageUsers from './pages/ManageUsers';
import SignupSubEmployeeForm from './pages/Signup_sub_emp_form';
import SignupSubEmployeeMobile from './pages/Signup_sub_emp_mobile';
import SubEmployerCreateProfile from './pages/sub_employer_create_profile';
import ForgetPassword from './pages/ForgetPassword';
import CancellationAndRefund from './pages/cancellationAndRefund';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Layout><Home /> </Layout>} />
          <Route path="/crew-home" element={ <Layout><HomeCrew /> </Layout>} />
          <Route path="/about" element={<Layout><About /></Layout>} />
          <Route path="/contact" element={<Layout><Contact /></Layout>} />
          
          {/* Login Register Starts */}
          <Route path={apiRoutes.login} element={<LayoutNoToken><Login /></LayoutNoToken>} />
          <Route path={apiRoutes.forget_password} element={<LayoutNoToken><ForgetPassword /></LayoutNoToken>} />
          <Route path="/register" element={<LayoutNoToken><Signup /></LayoutNoToken>} />
          {/* Login Register Ends */}

          {/* Crew Register Starts */}
          <Route path="/register/crew" element={<LayoutNoToken><SignupCrew /></LayoutNoToken>} />
          <Route path="/register/crew/confirmmail" element={<LayoutNoToken><CrewConfirmEmail /></LayoutNoToken>} />
          {/* Crew Register Ends */}

          {/* Crew Create Profile Starts */}
          <Route path="/crew/create-profile/step1" element={<Layout><CrewCreateProfileStep1 /></Layout>} />
          <Route path="/crew/create-profile/step2" element={<Layout><CrewCreateProfileStep2 /></Layout>} />
          <Route path="/crew/create-profile/step3" element={<Layout><CrewCreateProfileStep3 /></Layout>} />
          {/* Crew Create Profile Ends */}

          {/* Crew Urls Starts */}
          <Route path="/crew/find-jobs" element={<Layout><CrewFindJobs /></Layout>} />
          <Route path={apiRoutes.crew_discover} element={<Layout><CrewDiscover /></Layout>} />
          <Route path={apiRoutes.crew_job_refer} element={<Layout><CrewJobRefer /></Layout>} />
          <Route path={`${apiRoutes.crew_job_refer_edit}:jobID`} element={<Layout><CrewJobReferEdit /></Layout>} />
          <Route path="/crew/my-jobs" element={<Layout><CrewMyJobs /></Layout>} />
          <Route path={`${apiRoutes.company_jobs}:empID`} element={<Layout><CompanyJobs /></Layout>} />
          <Route path={`${apiRoutes.job_show}:empID/:jobID`} element={<Layout><JobShow /></Layout>} />
          <Route path={`${apiRoutes.get_subscriptions}`} element={<Layout><CrewSubscriptionPlan /></Layout>} />
          <Route path={apiRoutes.get_wallet} element={<Layout><CrewWallet /></Layout>} />
          <Route path={apiRoutes.crew_refer_earn} element={<Layout><ReferAndEarn /></Layout>} />
          <Route path={apiRoutes.crew_help_support} element={<Layout><CrewHelpSupport /></Layout>} />
          <Route path={apiRoutes.crew_settings} element={<Layout><CrewSettings /></Layout>} />
          
          {/* Crew Urls Ends */}


          {/* empployee Register Starts */}
          <Route path="/register/employer" element={<LayoutNoToken><SignupEmployee /></LayoutNoToken>} />
          <Route path="/register/employer/mobile" element={<LayoutNoToken><SignupEmployeeMobile /></LayoutNoToken>} />
          <Route path="/register/employer/mobile/otp" element={<LayoutNoToken><SignupEmployeeMobileVerify /></LayoutNoToken>} />
          <Route path={apiRoutes.register_employer_form} element={<LayoutNoToken><SignupEmployeeForm /></LayoutNoToken>} />
          <Route path="/register/employer/confirmmail" element={<LayoutNoToken><EmployerConfirmEmail /></LayoutNoToken>} />
          <Route path={apiRoutes.login_mobile} element={<LayoutNoToken><LogInWithMobile /></LayoutNoToken>} />
          <Route path={apiRoutes.emp_home} element={<LayoutNoToken><HomeEmployer /></LayoutNoToken>} />
          <Route path={apiRoutes.employer_find_job} element={<Layout><EmployerFindJobs /></Layout>} />
          <Route path={apiRoutes.employer_postjob} element={<Layout><PostJob /></Layout>} />
          <Route path={`${apiRoutes.employer_postjob_edit}:jobID`} element={<Layout><PostJobEdit /></Layout>} />
          <Route path={apiRoutes.employer_search_cv} element={<Layout><CvSearch /></Layout>} />
          <Route path={`${apiRoutes.employer_view_applicants}:jobID`} element={<Layout><EmployerViewApplicants /></Layout>} />
          <Route path={apiRoutes.employer_my_jobs} element={<Layout><EmployerMyJobs /></Layout>} />
          <Route path={`${apiRoutes.employer_view_profile}:profileID`} element={<Layout><EmployerViewProfile /></Layout>} />
          <Route path={`${apiRoutes.employer_view_applicant_profile}:profileID/:jobId`} element={<Layout><EmployerViewApplicantProfile /></Layout>} />
          <Route path={apiRoutes.employer_get_subscriptions} element={<Layout><EmployerSubscriptionPlan /></Layout>} />
          <Route path={apiRoutes.employer_manage_user} element={<Layout><ManageUsers /></Layout>} />
          <Route path={`${apiRoutes.sub_employer_create_byLink}:usercode`} element={<Layout><SignupSubEmployeeMobile /></Layout>} />
          <Route path={`${apiRoutes.sub_employer_form}:usercode`} element={<Layout><SignupSubEmployeeForm /></Layout>} />
          {/* empployee Register Ends */}

          {/* Employer Create Profile Starts */}
          <Route path={apiRoutes.create_profile_employer} element={<Layout><EmployerCreateProfile /></Layout>} />
          <Route path={apiRoutes.create_profile_sub_employer} element={<Layout><SubEmployerCreateProfile /></Layout>} />
          {/* <Route path="/crew/create-profile/step2" element={<Layout><CrewCreateProfileStep2 /></Layout>} />
          <Route path="/crew/create-profile/step3" element={<Layout><CrewCreateProfileStep3 /></Layout>} /> */}
          {/* Employer Create Profile Ends */}

          {/* Others Static Pages Starts */}
          <Route path={apiRoutes.cancellation_and_refund} element={<Layout><CancellationAndRefund /></Layout>} />
          <Route path={apiRoutes.terms_and_conditions} element={<Layout><TermsAndConditions /></Layout>} />
          <Route path={apiRoutes.privacy_policies} element={<Layout><PrivacyPolicies /></Layout>} />
          {/* Others Static Pages Ends */}


          <Route path="/testapp" element={<TestApp />} />
          <Route path="/demotestapp" element={<DemoApi />} />
          <Route path="/get-firebase-token" element={<FirebaseToken />} />
          <Route path="/get-login-token" element={<LoginTokenWithFirebaseToken />} />
          <Route path="/clear-localstorage" element={<ClearLocalStorage />} />
          {/* <Route path="*" element={<Page404 />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
